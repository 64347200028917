import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductUpdateHttpService } from './product-update-http.service';

@Component({
  selector: 'hpm-product-update',
  templateUrl: './product-update.component.html',
  styleUrl: './product-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductUpdateComponent {
  constructor(private productUpdateHttpService: ProductUpdateHttpService) {}

  getProductFileEndpoint(): string {
    return this.productUpdateHttpService.getProductFileEndpoint();
  }
}
