<div class="wordtemplate-update">

    <h3>{{ 'ADMIN.WORD_TEMPLATE_ADMIN.HEADLINE' | translate }}</h3>
    <div>{{ 'ADMIN.WORD_TEMPLATE_ADMIN.DESCRIPTION' | translate }}</div>

    <div class="addNew">
        <button mat-stroked-button color="accent" (click)="addWordTemplate()">
            <mat-icon>add</mat-icon>
            {{ 'ADMIN.WORD_TEMPLATE_ADMIN.ADD' | translate }}
        </button>
    </div>

    <div class="template-list row">
        <button mat-icon-button (click)="toggleListOpen()" class="list-toggle-button">
            @if (listOpen) {
                <mat-icon>keyboard_arrow_up</mat-icon>
            } @else {
                <mat-icon>keyboard_arrow_down</mat-icon>
            }
        </button>
        <cx-list class="col-7" [listTitle]="'ADMIN.WORD_TEMPLATE_ADMIN.LIST_TITLE'|translate">
            @for (wordTemplate of visibleWordTemplates; track wordTemplate) {
                <cx-list-item [itemTitle]="getName(wordTemplate) ">
                    <button class="cx-icon-stroked-button" mat-icon-button (click)="selectWordTemplate(wordTemplate)">
                        <mat-icon class="material-symbols-outlined">upload</mat-icon>
                    </button>
                    <button class="cx-icon-stroked-button" mat-icon-button
                            [hpmConfirmDelete]="wordTemplate" (confirmation)="deleteWordTemplate(wordTemplate, $event)">
                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                    </button>
                </cx-list-item>
            }
        </cx-list>
    </div>

    <ng-template #wordtemplateUpdateDialog>
        <div class="wordtemplate-preview">
            @if (selectedWordTemplate) {
                <p> {{ selectedWordTemplate }}</p>
            }
            @if (selectedWordTemplate && uploadedWordTemplate) {
                <div>{{ 'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.REPLACE_BY' | translate }}</div>
            }
            @if (uploadedWordTemplate) {
                <p> {{ uploadedWordTemplateName }}</p>
            }
        </div>

        <cx-file-uploader
                [heading]=" 'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.UPLOADER.HEADLINE' | translate"
                [description]=" 'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.UPLOADER.DESCRIPTION' | translate"
                [buttonText]=" 'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.UPLOADER.BUTTON' | translate"
                [errorMessage]=" 'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.UPLOADER.ERROR_MESSAGE' | translate"
                [acceptedFileTypes]="'application/vnd.openxmlformats-officedocument.wordprocessingml.template'"
                (removeFile)="fileRemoved($event)"
                (selectFile)="fileSelected($event)"
                [manualUpload]=true
        ></cx-file-uploader>
    </ng-template>


</div>
