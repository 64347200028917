import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { environment } from '../environments/environment';
import { BackendVersionHttpService } from './shared/service/backend-version-http.service';

@Component({
  selector: 'hpm-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  title = 'hpmApplication';

  constructor(
    translate: TranslateService,
    private snackbarService: CxSnackbarService,
    private viewContainerRef: ViewContainerRef,
    private backendVersionHttpService: BackendVersionHttpService,
  ) {
    translate.addLangs(['de']);
    translate.setDefaultLang('de');
    translate.use('de');

    console.log('Frontend Github Ref Number: ' + environment.gitHubRefNumber);
    this.backendVersionHttpService.getVersion().subscribe((versionInfo) => {
      if (versionInfo !== null) {
        console.log('Backend Github Ref Number: ' + versionInfo.gitHubRef);
      }
    });
  }

  ngAfterViewInit(): void {
    this.snackbarService.setViewContainerRef(this.viewContainerRef);
  }
}
