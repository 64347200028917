import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'hpm-sap-mapping-update',
  templateUrl: './sap-mapping-update.component.html',
  styleUrl: './sap-mapping-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SapMappingUpdateComponent {
  getEndpoint(): string {
    return `${environment.baseUrl}/specialties/mapping/sap`;
  }
}
