import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductUpdateHttpService {
  private readonly productBaseUrl = `${environment.baseUrl}/productProposals`;

  getProductFileEndpoint(): string {
    return this.productBaseUrl + '/file';
  }
}
