<h3>{{ 'ADMIN.PRODUCT_UPDATE.HEADLINE' | translate }}</h3>

<p class="description">
  {{ 'ADMIN.PRODUCT_UPDATE.DESCRIPTION' | translate }}
</p>
<div class="row gap">
  <div class="col-6 download">
    <div>
      <h3 class="download-title">{{ 'ADMIN.PRODUCT_UPDATE.DOWNLOAD.HEADING' | translate }}</h3>
      <p class="cx-file-uploader-description">{{ 'ADMIN.PRODUCT_UPDATE.DOWNLOAD.DESCRIPTION' | translate }}</p>
      <a hpmSecureDownload [url]="getProductFileEndpoint()" fileName="product-proposals.json">
        <button color="accent" mat-flat-button>
          {{ 'ADMIN.PRODUCT_UPDATE.DOWNLOAD.BUTTON' | translate }}
        </button>
      </a>
    </div>
  </div>
  <div class="col-6">
    <hpm-file-uploader
      [endpoint]="getProductFileEndpoint()"
      [multiple]="false"
      acceptedFileTypes="application/json"
      buttonText="{{ 'ADMIN.PRODUCT_UPDATE.UPLOAD.BUTTON' | translate }}"
      description="{{ 'ADMIN.PRODUCT_UPDATE.UPLOAD.DESCRIPTION' | translate }}"
      errorMessage="{{ 'ADMIN.PRODUCT_UPDATE.UPLOAD.ERROR_MESSAGE' | translate }}"
      heading="{{ 'ADMIN.PRODUCT_UPDATE.UPLOAD.HEADING' | translate }}">
    </hpm-file-uploader>
  </div>
</div>
