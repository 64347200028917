<div class="icon-update">

<h3>{{ 'ADMIN.ICON_UPDATE.HEADLINE' | translate }}</h3>
<div>{{ 'ADMIN.ICON_UPDATE.DESCRIPTION' | translate }}</div>

<div class="addIcon">
  <button mat-stroked-button color="accent" (click)="addIcon()">
    <mat-icon>add</mat-icon>
    {{ 'ADMIN.ICON_UPDATE.ADD' | translate }}
  </button>
</div>

<div class="icon-list row">
  <button mat-icon-button (click)="toggleListOpen()" class="list-toggle-button">
    @if (listOpen) {
    <mat-icon>keyboard_arrow_up</mat-icon>
    } @else {
    <mat-icon>keyboard_arrow_down</mat-icon>
    }
  </button>
  <cx-list class="col-7" [listTitle]="'ADMIN.ICON_UPDATE.ICON_LIST'|translate">
      @for (icon of visibleIcons; track icon) {
        <cx-list-item [itemTitle]="getIconName(icon)">
          <cx-image-thumbnail [imgSrc]="getIconPath(icon)"></cx-image-thumbnail>
          <button class="cx-icon-stroked-button" mat-icon-button (click)="selectIcon(icon)">
            <mat-icon class="material-symbols-outlined">upload</mat-icon>
          </button>
          <button class="cx-icon-stroked-button" mat-icon-button
                  [hpmConfirmDelete]="icon" (confirmation)="deleteIcon($event, icon)">
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
          </button>
        </cx-list-item>
      }
  </cx-list>
</div>

<ng-template #iconUpdateDialog>
  @if (selectedIcon) {
    {{ getIconName(selectedIcon!) }}
  } @else if (!selectedIcon && uploadedIcon) {
    {{ files[0].file.name }}
  }
  <div class="icon-preview">
    @if (selectedIcon) {
      <img [src]="getIconPath(selectedIcon)" [alt]="getIconName(selectedIcon)">
    }
    @if (selectedIcon && uploadedIcon) {
      <div>{{ 'ADMIN.ICON_UPDATE.UPLOAD_DIALOG.REPLACE_BY' | translate }}</div>
    }
    @if (uploadedIcon) {
      <img [src]="uploadedIcon" [alt]="'new Uploaded Icon'">
    }
  </div>

  <cx-file-uploader
    [heading]=" 'ADMIN.ICON_UPDATE.UPLOAD_DIALOG.UPLOADER.HEADLINE' | translate"
    [description]=" 'ADMIN.ICON_UPDATE.UPLOAD_DIALOG.UPLOADER.DESCRIPTION' | translate"
    [buttonText]=" 'ADMIN.ICON_UPDATE.UPLOAD_DIALOG.UPLOADER.BUTTON' | translate"
    [errorMessage]=" 'ADMIN.ICON_UPDATE.UPLOAD_DIALOG.UPLOADER.ERROR_MESSAGE' | translate"
    [acceptedFileTypes]="'image/svg+xml'"
    [manualUpload]="true"
    [files]="files"
    (selectFile)="fileSelected($event)"
    (removeFile)="fileRemoved($event)"
  ></cx-file-uploader>
</ng-template>

</div>
