import { ErrorHandler, Injectable } from '@angular/core';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingIndicatorService } from './loading-indicator.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private snackbarService: CxSnackbarService,
    private loadingService: LoadingIndicatorService,
    private translate: TranslateService,
  ) {}

  public handleError(error: unknown): void {
    console.error('error', error);
    if (error instanceof Error && error.message) {
      this.snackbarService.error(error.message);
    } else if (error instanceof HttpErrorResponse) {
      this.handleHttpErrors(error);
    } else {
      this.showErrorSnackbar('ERROR.UNKNOWN_ERROR');
    }
    this.loadingService.closeAll();
  }

  private handleHttpErrors(error: HttpErrorResponse): void {
    if (error.status === 401 || error.status === 403) {
      this.showErrorSnackbar('ERROR.UNAUTHORIZED');
    } else if (error.status === 404) {
      this.showErrorSnackbar('ERROR.NOT_FOUND');
    } else if (error.status.toString().startsWith('5')) {
      this.showErrorSnackbar('ERROR.SERVER_ERROR');
    } else {
      this.showErrorSnackbar('ERROR.UNKNOWN_ERROR');
    }
  }

  private showErrorSnackbar(key: string): void {
    this.translate.get(key).subscribe((errorMessage) => {
      this.snackbarService.error(errorMessage);
    });
  }
}
