import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExternalPartner } from './external-partner.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalPartnerHttpService {
  private readonly baseUrl = `${environment.baseUrl}/partners`;

  constructor(private httpClient: HttpClient) {}

  getDownloadEndpoint(): string {
    return `${this.baseUrl}/download`;
  }

  getUploadEndpoint(): string {
    return `${this.baseUrl}/upload`;
  }

  getExternalPartners(): Observable<ExternalPartner[]> {
    return this.httpClient.get<ExternalPartner[]>(`${this.baseUrl}`);
  }

  upsertExternerPartnerLogo(siteId: string, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<void>(
      `${this.baseUrl}/${siteId}/logo`,
      formData,
    );
  }

  getLogoEndpoint(siteId: string): string {
    return `${this.baseUrl}/logo/${siteId}`;
  }

  deleteLogoForExternalPartnerSite(siteId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${siteId}/logo`);
  }
}
