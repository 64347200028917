import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IconHttpService } from '../../shared/service/icon-http.service';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';

@Component({
  selector: 'hpm-manage-icons',
  templateUrl: './manage-icons.component.html',
  styleUrl: './manage-icons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageIconsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  @ViewChild('iconUpdateDialog') templateRef: TemplateRef<never> | undefined;
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  selectedIcon: string | undefined;
  uploadedIcon: string | ArrayBuffer | null | undefined;
  files: Array<CxFileProgress> = [];
  allIcons: string[] = [];
  visibleIcons: string[] = [];
  listOpen = false;
  imageChangeCounter = 0;

  constructor(
    private iconService: IconHttpService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadAllIcons();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadAllIcons(): void {
    this.iconService
      .loadAllIcons()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((iconUrls) => {
        this.allIcons = iconUrls.sort();
        if (this.listOpen) {
          this.visibleIcons = this.allIcons;
        }
        this.cdr.detectChanges();
      });
  }

  getIconPath(iconName: string): string {
    // imageChangeCounter appended to Icon URL to force the browser to reload the image on change
    return `${this.iconService.getIconUrl(iconName)}?${this.imageChangeCounter}`;
  }

  getIconName(iconPath: string): string {
    const pathParts = iconPath.split('/');
    const filename = pathParts[pathParts.length - 1];
    return filename.split('.')[0];
  }

  toggleListOpen(): void {
    this.listOpen = !this.listOpen;
    if (this.listOpen) {
      this.visibleIcons = this.allIcons;
    } else {
      this.visibleIcons = [];
    }
    this.cdr.detectChanges();
  }

  saveIcon(): void {
    let iconName: string;
    if (this.selectedIcon) {
      iconName = this.selectedIcon;
    } else {
      iconName = 'icons/' + this.files[0].file.name;
    }
    this.iconService
      .saveIcon(iconName, this.files[0].file)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.imageChangeCounter++;
        this.loadAllIcons();
      });
  }

  deleteIcon(confirmation: boolean, icon: string): void {
    if (confirmation) {
      this.iconService
        .deleteIcon(icon)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadAllIcons();
        });
    }
  }

  addIcon(): void {
    this.openDialog('ADMIN.ICON_UPDATE.UPLOAD_DIALOG.HEADLINE_NEW');
  }

  selectIcon(icon: string): void {
    this.selectedIcon = icon;
    this.openDialog('ADMIN.ICON_UPDATE.UPLOAD_DIALOG.HEADLINE_UPDATE');
  }

  private async openDialog(headlineKey: string): Promise<void> {
    if (!this.dialogRef) {
      const config = await this.getUpdateDialogConfig(headlineKey);
      this.dialogRef = this.dialogService.openDialog(config);
      this.dialogRef.disableClose = false;
      this.dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.saveIcon();
        }
        this.selectedIcon = undefined;
        this.uploadedIcon = undefined;
        this.files = [];
        this.dialogRef = undefined;
      });
    }
  }

  private async getUpdateDialogConfig(
    headline: string,
  ): Promise<CxDialogConfig> {
    const [title, cancel, confirm] = await firstValueFrom(
      forkJoin([
        this.translateService.get(headline),
        this.translateService.get('ADMIN.ICON_UPDATE.UPLOAD_DIALOG.CANCEL'),
        this.translateService.get('ADMIN.ICON_UPDATE.UPLOAD_DIALOG.CONFIRM'),
      ]),
    );

    return {
      title: title,
      confirmButtons: [{ text: confirm, value: true }],
      cancelButtons: [{ text: cancel, value: false }],
      template: this.templateRef,
    } as CxDialogConfig;
  }

  fileSelected(selectedFiles: File[]): void {
    this.files = selectedFiles.map((file) => {
      return { file } as CxFileProgress;
    });

    const reader = new FileReader();
    reader.onload = (e): void => {
      this.uploadedIcon = e.target?.result;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(selectedFiles[0]);
  }

  fileRemoved(removedFile: File): void {
    const removeIndex = this.files.findIndex(
      (files) => files.file.name === removedFile.name,
    );
    this.files.splice(removeIndex, 1);
    this.uploadedIcon = undefined;
  }
}
