import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserHttpService } from '../shared/service/user-http.service';
import { map } from 'rxjs';
import { PERMISSION_DENIED } from './error-page/error-page.component';

export const userRoleGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserHttpService);
  const router = inject(Router);

  return userService.getCurrentUser().pipe(
    map((user) => {
      if (!user || user.roles.length === 0) {
        router.navigate(['/error/' + PERMISSION_DENIED]);
        return false;
      }
      return true;
    }),
  );
};
