import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { ProductUpdateComponent } from './product-update/product-update.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '../ui.module';
import { UtilModule } from '../shared/util/util.module';
import { SharedModule } from '../shared/shared.module';
import { SapMappingUpdateComponent } from './sap-mapping-update/sap-mapping-update.component';
import { ManageIconsComponent } from './manage-icons/manage-icons.component';
import { WordTemplateAdminComponent } from './word-template-admin/word-template-admin.component';
import { ExternalPartnerUpdateComponent } from './external-partner-update/external-partner-update.component';

@NgModule({
  declarations: [
    AdminComponent,
    ProductUpdateComponent,
    SapMappingUpdateComponent,
    ManageIconsComponent,
    WordTemplateAdminComponent,
    ExternalPartnerUpdateComponent,
  ],
  imports: [TranslateModule, UIModule, UtilModule, SharedModule],
})
export class AdminModule {}
