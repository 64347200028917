import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';
import { ExternalPartnerHttpService } from './external-partner-http.service';
import { ExternalPartner } from './external-partner.model';

@Component({
  selector: 'hpm-external-partner-update',
  templateUrl: './external-partner-update.component.html',
  styleUrl: './external-partner-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExternalPartnerUpdateComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  @ViewChild('updateDialog') templateRef: TemplateRef<never> | undefined;
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  selectedPartner: ExternalPartner | undefined;
  uploadedLogo: string | ArrayBuffer | null | undefined;
  files: Array<CxFileProgress> = [];
  allPartners: ExternalPartner[] = [];
  visiblePartners: ExternalPartner[] = [];
  listOpen = false;
  logoChangeCounter = 0;

  constructor(
    private externalPartnerHttpService: ExternalPartnerHttpService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  getDownloadEndpoint(): string {
    return this.externalPartnerHttpService.getDownloadEndpoint();
  }

  getUploadEndpoint(): string {
    return this.externalPartnerHttpService.getUploadEndpoint();
  }

  ngOnInit(): void {
    this.loadAllPartners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadAllPartners(): void {
    this.externalPartnerHttpService
      .getExternalPartners()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((externalPartners) => {
        this.allPartners = externalPartners;
        if (this.listOpen) {
          this.visiblePartners = this.allPartners;
        }
        this.cdr.detectChanges();
      });
  }

  toggleListOpen(): void {
    this.listOpen = !this.listOpen;
    if (this.listOpen) {
      this.visiblePartners = this.allPartners;
    } else {
      this.visiblePartners = [];
    }
    this.cdr.detectChanges();
  }

  getLogoPath(siteId: string): string {
    return `${this.externalPartnerHttpService.getLogoEndpoint(siteId)}?${this.logoChangeCounter}`;
  }

  deletePartnerLogo(confirmation: boolean, siteId: string): void {
    if (confirmation) {
      this.externalPartnerHttpService
        .deleteLogoForExternalPartnerSite(siteId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.logoChangeCounter++;
          this.loadAllPartners();
        });
    }
  }

  selectPartner(partner: ExternalPartner): void {
    this.selectedPartner = partner;
    this.openDialog();
  }

  private async openDialog(): Promise<void> {
    if (!this.dialogRef) {
      const config = await this.getUpdateDialogConfig();
      this.dialogRef = this.dialogService.openDialog(config);
      this.dialogRef.disableClose = false;
      this.dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm && this.selectedPartner) {
          this.externalPartnerHttpService
            .upsertExternerPartnerLogo(
              this.selectedPartner.siteId,
              this.files[0].file,
            )
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.logoChangeCounter++;
              this.loadAllPartners();
            });
        }
        this.selectedPartner = undefined;
        this.uploadedLogo = undefined;
        this.files = [];
        this.dialogRef = undefined;
      });
    }
  }

  private async getUpdateDialogConfig(): Promise<CxDialogConfig> {
    const [title, cancel, confirm] = await firstValueFrom(
      forkJoin([
        this.translateService.get(
          'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.HEADLINE_UPDATE',
        ),
        this.translateService.get(
          'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.CANCEL',
        ),
        this.translateService.get(
          'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.CONFIRM',
        ),
      ]),
    );

    return {
      title: title,
      confirmButtons: [{ text: confirm, value: true }],
      cancelButtons: [{ text: cancel, value: false }],
      template: this.templateRef,
    } as CxDialogConfig;
  }

  fileSelected(selectedFiles: File[]): void {
    this.files = selectedFiles.map((file) => {
      return { file } as CxFileProgress;
    });

    const reader = new FileReader();
    reader.onload = (e): void => {
      this.uploadedLogo = e.target?.result;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(selectedFiles[0]);
  }

  fileRemoved(removedFile: File): void {
    const removeIndex = this.files.findIndex(
      (files) => files.file.name === removedFile.name,
    );
    this.files.splice(removeIndex, 1);
    this.uploadedLogo = undefined;
  }

  getSubtitle(partner: ExternalPartner): string {
    let subtitle = partner.name ? 'SiteId: ' + partner.siteId + '\n' : '';
    subtitle += 'SapId: ' + partner.sapId;
    return subtitle;
  }
}
