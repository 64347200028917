import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, forkJoin, Subject, takeUntil } from 'rxjs';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';
import { MatDialogRef } from '@angular/material/dialog';
import {
  CxDialogComponent,
  CxDialogConfig,
  CxDialogService,
} from '@bbraun/cortex/dialog';
import { TranslateService } from '@ngx-translate/core';
import { WordTemplateHttpService } from '../../template/template-overview/word-template-http.service';

@Component({
  selector: 'hpm-word-template-admin',
  templateUrl: './word-template-admin.component.html',
  styleUrl: './word-template-admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WordTemplateAdminComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  allWordTemplates: string[] = [];
  selectedWordTemplate: string | undefined;
  uploadedWordTemplate: string | ArrayBuffer | null | undefined;
  uploadedWordTemplateName: string | undefined;
  files: Array<CxFileProgress> = [];
  dialogRef: MatDialogRef<CxDialogComponent> | undefined;
  visibleWordTemplates: string[] = [];
  listOpen = false;
  @ViewChild('wordtemplateUpdateDialog') templateRef:
    | TemplateRef<never>
    | undefined;

  constructor(
    private wordTemplateService: WordTemplateHttpService,
    private dialogService: CxDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadWordTemplates();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private loadWordTemplates(): void {
    this.wordTemplateService
      .getAllWordTemplates()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((wordTemplates) => {
        this.allWordTemplates = wordTemplates.sort();
        if (this.listOpen) {
          this.visibleWordTemplates = this.allWordTemplates;
        }
        this.cdr.detectChanges();
      });
  }

  getName(iconPath: string): string {
    const pathParts = iconPath.split('/');
    const filename = pathParts[pathParts.length - 1];
    return filename.split('.')[0];
  }

  private async getUpdateDialogConfig(
    headline: string,
  ): Promise<CxDialogConfig> {
    const [title, cancel, confirm] = await firstValueFrom(
      forkJoin([
        this.translateService.get(headline),
        this.translateService.get(
          'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.CANCEL',
        ),
        this.translateService.get(
          'ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.CONFIRM',
        ),
      ]),
    );

    return {
      title: title,
      confirmButtons: [{ text: confirm, value: true }],
      cancelButtons: [{ text: cancel, value: false }],
      template: this.templateRef,
    } as CxDialogConfig;
  }

  replaceWordTemplate(): void {
    let wordTemplate: string;
    if (this.selectedWordTemplate) {
      wordTemplate = this.selectedWordTemplate;
    } else {
      wordTemplate = this.files[0].file.name;
    }

    this.wordTemplateService
      .saveWordTemplate(wordTemplate, this.files[0].file)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadWordTemplates();
      });
  }

  toggleListOpen(): void {
    this.listOpen = !this.listOpen;
    if (this.listOpen) {
      this.visibleWordTemplates = this.allWordTemplates;
    } else {
      this.visibleWordTemplates = [];
    }
    this.cdr.detectChanges();
  }

  private async openDialog(headlineKey: string): Promise<void> {
    if (!this.dialogRef) {
      const config = await this.getUpdateDialogConfig(headlineKey);
      this.dialogRef = this.dialogService.openDialog(config);
      this.dialogRef.disableClose = false;
      this.dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.replaceWordTemplate();
        }
        this.selectedWordTemplate = '';
        this.uploadedWordTemplate = undefined;
        this.files = [];
        this.dialogRef = undefined;
      });
    }
  }

  deleteWordTemplate(wordTemplate: string, confirmation: boolean): void {
    if (confirmation) {
      this.wordTemplateService
        .deleteWordTemplate(wordTemplate)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadWordTemplates();
        });
    }
  }

  addWordTemplate(): void {
    this.openDialog('ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.HEADLINE_NEW');
  }

  selectWordTemplate(wordTemplate: string): void {
    this.selectedWordTemplate = wordTemplate;
    this.openDialog('ADMIN.WORD_TEMPLATE_ADMIN.UPLOAD_DIALOG.HEADLINE_UPDATE');
  }

  fileSelected(selectedFiles: File[]): void {
    this.files = selectedFiles.map((file) => {
      this.uploadedWordTemplateName = file.name;
      return { file } as CxFileProgress;
    });

    const reader = new FileReader();
    reader.onload = (e): void => {
      this.uploadedWordTemplate = e.target?.result;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(selectedFiles[0]);
  }

  fileRemoved(removedFile: File): void {
    const removeIndex = this.files.findIndex(
      (files) => files.file.name === removedFile.name,
    );
    this.files.splice(removeIndex, 1);
    this.uploadedWordTemplate = undefined;
  }
}
