import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackendVersionHttpService {
  private readonly versionBaseUrl = `${environment.baseUrl}/version`;

  constructor(private httpClient: HttpClient) {}

  getVersion(): Observable<VersionInfo | null> {
    return this.httpClient.get<VersionInfo>(this.versionBaseUrl);
  }
}

export interface VersionInfo {
  gitHubRef: string;
}
