import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrderModule } from './order/order.module';
import { TemplateModule } from './template/template.module';
import { CxMaterialConfigProviders } from '@bbraun/cortex';
import { LoginModule } from './login/login.module';
import { SNACKBAR_CONFIG, SnackbarGlobalConfig } from '@bbraun/cortex/snackbar';
import { GlobalErrorHandler } from './shared/service/global-error-handler.service';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { OrderDetailModule } from './order/order-detail/order-detail.module';
import { AdminModule } from './admin/admin.module';
import { UIModule } from './ui.module';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './app.config';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CxListModule } from '@bbraun/cortex/list';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const customSnackbarConfig: SnackbarGlobalConfig = {
  maxOpened: 5,
  closeButton: false,
  timeout: 5000,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AdminModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OrderModule,
    CxListModule,
    TemplateModule,
    LoginModule,
    UIModule,
    MsalModule,
    OrderDetailModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ...CxMaterialConfigProviders,
    { provide: SNACKBAR_CONFIG, useValue: customSnackbarConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatSnackBarConfig, useValue: customSnackbarConfig },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
