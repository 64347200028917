import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { AdminComponent } from './admin/admin.component';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorPageComponent } from './login/error-page/error-page.component';
import { userRoleGuard } from './login/user-role.guard';

const routes: Routes = [
  { path: '', component: LoginPageComponent },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
    canActivate: [MsalGuard, userRoleGuard],
  },
  {
    path: 'order',
    pathMatch: 'full',
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderModule),
    canActivate: [MsalGuard, userRoleGuard],
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./template/template.module').then((m) => m.TemplateModule),
    canActivate: [MsalGuard, userRoleGuard],
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order/order-detail/order-detail.module').then(
        (m) => m.OrderDetailModule,
      ),
    canActivate: [MsalGuard, userRoleGuard],
  },
  {
    path: 'error/:id',
    component: ErrorPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: 'error/not_found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
